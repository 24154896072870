// NPM Packages
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Icon,
  Label,
  Menu,
  Sidebar,
} from 'semantic-ui-react'

// Custom Modules
import { SignOut } from './../../../../containers';

// Styles
import './index.css';

// Constants
const LINK_ACCOUNT_AS = 'a';
const LINK_ACCOUNT_ID = 'account';
const LINK_ACCOUNT_LINK = 'true'; // Dropdown.Item -> 'true'
const LINK_ACCOUNT_LINK_TO = '/account';
const LINK_CODE_AS = 'a';
const LINK_CODE_ICON_NAME = 'code';
const LINK_CODE_ID = 'code';
const LINK_CODE_LINK = true;
const LINK_CODE_LINK_TO = '/code/explore/repos';
const LINK_CODE_LINK_TARGET = '_blank';
const LINK_CONTACT_AS = 'a';
const LINK_CONTACT_ID = 'contact';
const LINK_CONTACT_LINK = true;
const LINK_CONTACT_LINK_TO = '/contact';
const LINK_HOME_AS = 'a';
const LINK_HOME_ID = 'home';
const LINK_HOME_LINK = true;
const LINK_HOME_LINK_TO = '/';
const LINK_PLANS_AND_PRICING_AS = 'a';
const LINK_PLANS_AND_PRICING_ID = 'plans-and-pricing';
const LINK_PLANS_AND_PRICING_LINK = true;
const LINK_PLANS_AND_PRICING_LINK_TO = '/plans-and-pricing';
const LINK_PAYMENTS_AS = 'a';
const LINK_PAYMENTS_ICON_NAME = 'list';
const LINK_PAYMENTS_ID = 'payments';
const LINK_PAYMENTS_LINK = true;
const LINK_PAYMENTS_LINK_TO = '/payments';
const LINK_USERS_AS = 'a';
const LINK_USERS_ICON_NAME = 'users';
const LINK_USERS_ID = 'users';
const LINK_USERS_LINK = true;
const LINK_USERS_LINK_TO = '/users';
const LINK_VIDEOS_AS = 'a';
const LINK_VIDEOS_ICON_NAME = 'play';
const LINK_VIDEOS_ID = 'videos';
const LINK_VIDEOS_LINK = true;
const LINK_VIDEOS_LINK_TO = '/videos';
const MENU_BUTTONS_POSTIONS = 'right';
const SIDEBAR_MENU_ANIMATION = 'overlay';
const SIDEBAR_MENU_BUTTON_SIGN_IN_COLOR = 'grey';
const SIDEBAR_MENU_BUTTON_SIGN_IN_INVERTED = false;
const SIDEBAR_MENU_BUTTON_SIGN_UP_COLOR = 'purple';
const SIDEBAR_MENU_BUTTON_SIGN_UP_INVERTED = false;
const SIDEBAR_MENU_INVERTED = true;
const SIDEBAR_MENU_VERTICAL = true;
const USER_TYPE_CUSTOM = 'CUSTOM';
const USER_TYPE_FREEMIUM = 'FREEMIUM';
const USER_TYPE_PREMIUM = 'PREMIUM';

function SidebarMenu(props) {
  // Constants
  const ADMIN_TYPE_LABEL = {
    color: 'red',
    horizontal: true,
    label: props.texts['ADMIN_TYPE_LABEL'],
    size: 'small',
  };

  const USER_TYPE_LABELS = {
    FREEMIUM: {
      color: 'blue',
      horizontal: true,
      label: props.texts['USER_TYPE_LABEL_FREEMIUM'],
      size: 'small',
    },
    PREMIUM: {
      color: 'purple',
      horizontal: true,
      label: props.texts['USER_TYPE_LABEL_PREMIUM'],
      size: 'small',
    },
    CUSTOM: {
      color: 'violet',
      horizontal: true,
      label: props.texts['USER_TYPE_LABEL_CUSTOM'],
      size: 'small',
    },
  };

  // Hooks
  const navigate = useNavigate();

  // Handlers
  function handleClickCodeLink() {
    window.open(LINK_CODE_LINK_TO, LINK_CODE_LINK_TARGET);
  }

  // Renderers
  function renderTopLinksAdmin() {
    return (
      <>
        <Menu.Item
          active={props.activePage === LINK_VIDEOS_ID}
          as={LINK_VIDEOS_AS}
          link={LINK_VIDEOS_LINK}
          onClick={_ => navigate(LINK_VIDEOS_LINK_TO)}
        >
          <Icon name={LINK_VIDEOS_ICON_NAME} />
          { props.texts['HEADER_LINK_VIDEOS_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_CODE_ID}
          as={LINK_CODE_AS}
          link={LINK_CODE_LINK}
          onClick={handleClickCodeLink}
        >
          <Icon name={LINK_CODE_ICON_NAME} />
          { props.texts['HEADER_LINK_CODE_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_PAYMENTS_ID}
          as={LINK_PAYMENTS_AS}
          link={LINK_PAYMENTS_LINK}
          onClick={_ => navigate(LINK_PAYMENTS_LINK_TO)}
        >
          <Icon name={LINK_PAYMENTS_ICON_NAME} />
          { props.texts['HEADER_LINK_PAYMENTS_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_USERS_ID}
          as={LINK_USERS_AS}
          link={LINK_USERS_LINK}
          onClick={_ => navigate(LINK_USERS_LINK_TO)}
        >
          <Icon name={LINK_USERS_ICON_NAME} />
          { props.texts['HEADER_LINK_USERS_LABEL'] }
        </Menu.Item>
      </>
    );
  }

  function renderTopLinksFreemium() {
    return (
      <>
        <Menu.Item
          active={props.activePage === LINK_HOME_ID}
          as={LINK_HOME_AS}
          link={LINK_HOME_LINK}
          onClick={_ => navigate(LINK_HOME_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_HOME_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_VIDEOS_ID}
          as={LINK_VIDEOS_AS}
          link={LINK_VIDEOS_LINK}
          onClick={_ => navigate(LINK_VIDEOS_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_VIDEOS_LABEL'] }
        </Menu.Item>
      </>
    );
  }

  function renderTopLinksPremium() {
    return (
      <>
        <Menu.Item
          active={props.activePage === LINK_HOME_ID}
          as={LINK_HOME_AS}
          link={LINK_HOME_LINK}
          onClick={_ => navigate(LINK_HOME_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_HOME_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_VIDEOS_ID}
          as={LINK_VIDEOS_AS}
          link={LINK_VIDEOS_LINK}
          onClick={_ => navigate(LINK_VIDEOS_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_VIDEOS_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_CODE_ID}
          as={LINK_CODE_AS}
          link={LINK_CODE_LINK}
          onClick={handleClickCodeLink}
        >
          { props.texts['SIDEBAR_LINK_CODE_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_PAYMENTS_ID}
          as={LINK_PAYMENTS_AS}
          link={LINK_PAYMENTS_LINK}
          onClick={_ => navigate(LINK_PAYMENTS_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_PAYMENTS_LABEL'] }
        </Menu.Item>
      </>
    );
  }

  function renderTopLinksCustom() {
    return (
      <>
        <Menu.Item
          active={props.activePage === LINK_HOME_ID}
          as={LINK_HOME_AS}
          link={LINK_HOME_LINK}
          onClick={_ => navigate(LINK_HOME_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_HOME_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_VIDEOS_ID}
          as={LINK_VIDEOS_AS}
          link={LINK_VIDEOS_LINK}
          onClick={_ => navigate(LINK_VIDEOS_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_VIDEOS_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_CODE_ID}
          as={LINK_CODE_AS}
          link={LINK_CODE_LINK}
          onClick={handleClickCodeLink}
        >
          { props.texts['SIDEBAR_LINK_CODE_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_PAYMENTS_ID}
          as={LINK_PAYMENTS_AS}
          link={LINK_PAYMENTS_LINK}
          onClick={_ => navigate(LINK_PAYMENTS_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_PAYMENTS_LABEL'] }
        </Menu.Item>
      </>
    );
  }

  function renderTopLinksWebsite() {
    return (
      <>
        <Menu.Item
          active={props.activePage === LINK_HOME_ID}
          as={LINK_HOME_AS}
          link={LINK_HOME_LINK}
          onClick={_ => navigate(LINK_HOME_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_HOME_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_PLANS_AND_PRICING_ID}
          as={LINK_PLANS_AND_PRICING_AS}
          link={LINK_PLANS_AND_PRICING_LINK}
          onClick={_ => navigate(LINK_PLANS_AND_PRICING_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_PLANS_AND_PRICING_LABEL'] }
        </Menu.Item>
        <Menu.Item
          active={props.activePage === LINK_CONTACT_ID}
          as={LINK_CONTACT_AS}
          link={LINK_CONTACT_LINK}
          onClick={_ => navigate(LINK_CONTACT_LINK_TO)}
        >
          { props.texts['SIDEBAR_LINK_CONTACT_LABEL'] }
        </Menu.Item>
      </>
    );
  }

  function renderTopLinks(fixed) {
    let result;

    if (
      props.data
      && props.data.currentUser
      && props.data.currentUser.id
      && props.data.currentUser.isAdmin
    ) {
      // Admin
      result = renderTopLinksAdmin(fixed);
    }
    else if (
      props.data
      && props.data.currentUser
      && props.data.currentUser.id
      && !props.data.currentUser.isAdmin
    ) {
      // User
      switch (props.data.currentUser.type) {
        case USER_TYPE_FREEMIUM:
          result = renderTopLinksFreemium(fixed);

          break;
        case USER_TYPE_PREMIUM:
          result = renderTopLinksPremium(fixed);

          break;
        case USER_TYPE_CUSTOM:
          result = renderTopLinksCustom(fixed);

          break;
        default:
          result = renderTopLinksFreemium(fixed);
      }
    }
    else {
      // Website
      result = renderTopLinksWebsite(fixed);
    }

    return result;
  }

  function renderBottomButtons() {
    let buttons;

    if (
      props.data
      && props.data.currentUser
      && props.data.currentUser.id
      && props.data.currentUser.isAdmin
    ) {
      // Admin
      buttons = (
        <>
          <span className="CurrentUserEmail">
            <Label
              color={ADMIN_TYPE_LABEL['color']}
              horizontal={
                ADMIN_TYPE_LABEL['horizontal']
              }
              size={ADMIN_TYPE_LABEL['size']}
            >
              { ADMIN_TYPE_LABEL['label'] }
            </Label>
            { renderUserDropdown() }
          </span>
        </>
      );
    }
    else if (
      props.data
      && props.data.currentUser
      && props.data.currentUser.id
      && !props.data.currentUser.isAdmin
    ) {
      // User
      buttons = (
        <>
          <span className="CurrentUserEmail">
            <Label
              color={USER_TYPE_LABELS[props.data.currentUser.type]['color']}
              horizontal={
                USER_TYPE_LABELS[props.data.currentUser.type]['horizontal']
              }
              size={USER_TYPE_LABELS[props.data.currentUser.type]['size']}
            >
              { USER_TYPE_LABELS[props.data.currentUser.type]['label'] }
            </Label>
            { renderUserDropdown() }
          </span>
        </>
      );
    }
    else {
      // Website
      buttons = (
        <>
          <Menu.Item>
            <Button
              color={SIDEBAR_MENU_BUTTON_SIGN_IN_COLOR}
              inverted={SIDEBAR_MENU_BUTTON_SIGN_IN_INVERTED}
              onClick={props.handlers.openPopupSignIn}
            >
              { props.texts['SIDEBAR_MENU_BUTTON_SIGN_IN_LABEL'] }
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              color={SIDEBAR_MENU_BUTTON_SIGN_UP_COLOR}
              disabled={process.env.REACT_APP_DISABLE_SIGN_UP === 'true'}
              inverted={SIDEBAR_MENU_BUTTON_SIGN_UP_INVERTED}
              onClick={props.handlers.openPopupSignUp}
            >
              { props.texts['SIDEBAR_MENU_BUTTON_SIGN_UP_LABEL'] }
            </Button>
          </Menu.Item>
        </>
      );
    }

    return (
      <Menu.Item position={MENU_BUTTONS_POSTIONS}>
        { buttons }
      </Menu.Item>
    );
  }

  function renderUserDropdown() {
    return (
      <Dropdown text={props.data.currentUser.email}>
        <Dropdown.Menu>
          <Dropdown.Item
            active={props.activePage === LINK_ACCOUNT_ID}
            as={LINK_ACCOUNT_AS}
            key={LINK_ACCOUNT_ID}
            link={LINK_ACCOUNT_LINK}
            onClick={_ => navigate(LINK_ACCOUNT_LINK_TO)}
            text={props.texts['HEADER_LINK_ACCOUNT_LABEL']}
          />
          <Dropdown.Divider />
          <Dropdown.Item
            text={
              <SignOut texts={props.texts} />
            }
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <Sidebar
      as={Menu}
      animation={SIDEBAR_MENU_ANIMATION}
      inverted={SIDEBAR_MENU_INVERTED}
      onHide={() => props.handlers.closeSidebar()}
      vertical={SIDEBAR_MENU_VERTICAL}
      visible={props.sidebarOpened}
    >
      { renderTopLinks() }

      { renderBottomButtons() }
    </Sidebar>
  );
}

export default SidebarMenu;
